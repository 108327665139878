<template>
    <div class="main box">
        <h1>Add {{ title }}</h1>
        <div class="form-container">            
              <div class="row">
                  <div class="col-50 col-type">
                      <div class="label">TYPE</div>
                      <div class="row row-type">
                            <div class="type-item">
                              <div :class="['type-input', {selected : type == 1}]" @click="type = 1"></div>
                              <div class="type-label">Standard</div>
                          </div>
                            <div class="type-item">
                              <div :class="['type-input', {selected : type == 2}]" @click="type = 2"></div>
                              <div class="type-label">Document only</div>
                          </div>
                            <div class="type-item">
                              <div :class="['type-input', {selected : type == 3}]" @click="type = 3"></div>
                              <div class="type-label">External link</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-50 col-type">
                      <div class="label">LANGUAGE</div>
                      <div class="row row-type">
                            <div class="type-item">
                              <div :class="['checkbox-input', {selected : langs.includes('en')}, {error : !langs.length}]" @click="switchLang('en')"></div>
                              <div class="type-label">English</div>
                          </div>
                            <div class="type-item">
                              <div :class="['checkbox-input', {selected : langs.includes('fr')}, {error : !langs.length}]" @click="switchLang('fr')"></div>
                              <div class="type-label">French</div>
                          </div>
                            <div class="type-item">
                              <div :class="['checkbox-input', {selected : langs.includes('de')}, {error : !langs.length}]" @click="switchLang('de')"></div>
                              <div class="type-label">German</div>
                          </div>
                      </div>
                      <div class="error-msg error-center" v-if="!langs.length">Select at least one language</div>
                  </div>
              </div>
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-text">
                    <!-- 
                    ***************************************************************************************  
                      BLOCK EN
                    ***************************************************************************************  
                     -->
                    <template v-if="langs.includes('en')">
                      <div class="form-lang">
                        <h2>English</h2>
                          
                          <div class="row">
                            <div class="col-100">
                              <!-- TITLE -->
                              <div class="form-group" :class="{ error: v$.form.en.title.$errors.length }">
                                  <input class="form-control" placeholder="TITLE" type="text" v-model="v$.form.en.title.$model">
                                  <!-- error message -->
                                  <template v-if="v$.form.en.title.$errors.length > 0">                
                                      <div class="error-msg" v-if="v$.form.en.title.$errors[0].$validator == 'required'">This field is required.</div>
                                      <div class="error-msg" v-if="v$.form.en.title.$errors[0].$validator == 'max'">{{v$.form.en.title.$errors[0].$params.max}} caracters maximum allowed.</div>
                                  </template>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="type == 1">
                            <div class="col-100">
                                <div :class="['editor-border',{ error: v$.form.en.text.$errors.length } ]">
                                  <editor
                                      :init="{
                                        plugins: 'lists link code wordcount',
                                        toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link emoticons'
                                      }"
                                      api-key="ayx8qinkntf9arti6bqpjugtqdcqdwkzkj2hzy81s3gzzgpx"
                                      v-model="v$.form.en.text.$model"
                                    />
                                </div>
                                <template v-if="v$.form.en.text.$errors.length > 0">                
                                    <div class="error-msg" v-if="v$.form.en.text.$errors[0].$validator == 'required'">This field is required.</div>
                                </template>
                            </div>
                          </div>
                          <div class="row row-more">
                            <div class="col-100">
                                <template v-if="type == 1 || type == 2">
                                  <!-- PDF -->
                                  <div class="form-group">
                                    <div class="label">PDF</div>
                                    <input class="form-control" v-on:change="handlePdfUpload($event, 'en')" type="file" v-if="!form.en.pdf" accept="application/pdf" />
                                    <div class="pdf-viewer" v-else>
                                        <div @click="openPdf(form.en.pdf)" class="pdf-name">{{form.en.pdf.split('_')[1]  }}</div>
                                        <div class="pdf-delete icon-otw_icon_trash" @click="deletePdf(form.en.pdf, 'en')"></div>
                                    </div>
                                    <div class="error-msg" v-if="errorPdfEN">{{ errorPdfEN }}</div>
                                  </div>
                                </template>
                                <template v-else>
                                  <!-- LINK -->
                                    <div class="form-group" :class="{ error: v$.form.en.link.$errors.length }">
                                      <div class="label">EXTERNAL LINK</div>
                                        <input class="form-control" placeholder="LINK" type="text" v-model="v$.form.en.link.$model">
                                        <!-- error message -->
                                        <template v-if="v$.form.en.link.$errors.length > 0">                
                                            <div class="error-msg" v-if="v$.form.en.link.$errors[0].$validator == 'required'">This field is required.</div>
                                            <div class="error-msg" v-if="v$.form.en.link.$errors[0].$validator == 'url'">Please select a valid URL format.</div>
                                            <div class="error-msg" v-if="v$.form.en.link.$errors[0].$validator == 'max'">{{v$.form.en.title.$errors[0].$params.max}} caracters maximum allowed.</div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                          </div>
                      </div>

                    </template>

                      <!-- 
                    ***************************************************************************************  
                      BLOCK FR
                    ***************************************************************************************  
                     -->
                    <template v-if="langs.includes('fr')">
                      <div class="form-lang">
                        <h2>French</h2>
                          
                          <div class="row">
                            <div class="col-100">
                              <!-- TITLE -->
                              <div class="form-group" :class="{ error: v$.form.fr.title.$errors.length }">
                                  <input class="form-control" placeholder="TITLE" type="text" v-model="v$.form.fr.title.$model">
                                  <!-- error message -->
                                  <template v-if="v$.form.fr.title.$errors.length > 0">                
                                      <div class="error-msg" v-if="v$.form.fr.title.$errors[0].$validator == 'required'">This field is required.</div>
                                      <div class="error-msg" v-if="v$.form.fr.title.$errors[0].$validator == 'max'">{{v$.form.fr.title.$errors[0].$params.max}} caracters maximum allowed.</div>
                                  </template>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="type == 1">
                            <div class="col-100">
                                <div :class="['editor-border',{ error: v$.form.fr.text.$errors.length } ]">
                                  <editor
                                      :init="{
                                        plugins: 'lists code wordcount link',
                                        toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link emoticons'
                                      }"
                                      api-key="ayx8qinkntf9arti6bqpjugtqdcqdwkzkj2hzy81s3gzzgpx"
                                      v-model="v$.form.fr.text.$model"
                                    />
                                </div>
                                <template v-if="v$.form.fr.text.$errors.length > 0">                
                                    <div class="error-msg" v-if="v$.form.fr.text.$errors[0].$validator == 'required'">This field is required.</div>
                                </template>
                            </div>
                          </div>
                          <div class="row row-more">
                            <div class="col-100">
                                <template v-if="type == 1 || type == 2">
                                  <!-- PDF -->
                                  <div class="form-group">
                                    <div class="label">PDF</div>
                                    <input class="form-control" v-on:change="handlePdfUpload($event, 'fr')" type="file" v-if="!form.fr.pdf" accept="application/pdf" />
                                    <div class="pdf-viewer" v-else>
                                        <div @click="openPdf(form.fr.pdf)" class="pdf-name">{{form.fr.pdf.split('_')[1]  }}</div>
                                        <div class="pdf-delete icon-otw_icon_trash" @click="deletePdf(form.fr.pdf, 'fr')"></div>
                                    </div>
                                    <div class="error-msg" v-if="errorPdfFR">{{ errorPdfFR }}</div>
                                  </div>
                                </template>
                                <template v-else>
                                  <!-- LINK -->
                                    <div class="form-group" :class="{ error: v$.form.fr.link.$errors.length }">
                                      <div class="label">EXTERNAL LINK</div>
                                        <input class="form-control" placeholder="LINK" type="text" v-model="v$.form.fr.link.$model">
                                        <!-- error message -->
                                        <template v-if="v$.form.fr.link.$errors.length > 0">                
                                            <div class="error-msg" v-if="v$.form.fr.link.$errors[0].$validator == 'required'">This field is required.</div>
                                            <div class="error-msg" v-if="v$.form.fr.link.$errors[0].$validator == 'url'">Please select a valid URL format.</div>
                                            <div class="error-msg" v-if="v$.form.fr.link.$errors[0].$validator == 'max'">{{v$.form.fr.title.$errors[0].$params.max}} caracters maximum allowed.</div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                          </div>
                      </div>

                    </template>


                        <!-- 
                    ***************************************************************************************  
                      BLOCK DE
                    ***************************************************************************************  
                     -->
                    <template v-if="langs.includes('de')">
                      <div class="form-lang">
                        <h2>German</h2>
                          
                          <div class="row">
                            <div class="col-100">
                              <!-- TITLE -->
                              <div class="form-group" :class="{ error: v$.form.de.title.$errors.length }">
                                  <input class="form-control" placeholder="TITLE" type="text" v-model="v$.form.de.title.$model">
                                  <!-- error message -->
                                  <template v-if="v$.form.de.title.$errors.length > 0">                
                                      <div class="error-msg" v-if="v$.form.de.title.$errors[0].$validator == 'required'">This field is required.</div>
                                      <div class="error-msg" v-if="v$.form.de.title.$errors[0].$validator == 'max'">{{v$.form.de.title.$errors[0].$params.max}} caracters maximum allowed.</div>
                                  </template>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="type == 1">
                            <div class="col-100">
                              <div :class="['editor-border',{ error: v$.form.de.text.$errors.length } ]">
                                  <editor
                                      :init="{
                                        plugins: 'lists link code wordcount',
                                        toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link emoticons'
                                      }"
                                      api-key="ayx8qinkntf9arti6bqpjugtqdcqdwkzkj2hzy81s3gzzgpx"
                                      v-model="v$.form.de.text.$model"
                                    />
                                </div>
                                <template v-if="v$.form.de.title.$errors.length > 0">                
                                    <div class="error-msg" v-if="v$.form.de.text.$errors[0].$validator == 'required'">This field is required.</div>
                                </template>
                            </div>
                          </div>
                          <div class="row row-more">
                            <div class="col-100">
                                <template v-if="type == 1 || type == 2">
                                  <!-- PDF -->
                                  <div class="form-group">
                                    <div class="label">PDF</div>
                                    <input class="form-control" v-on:change="handlePdfUpload($event, 'de')" type="file" v-if="!form.de.pdf" accept="application/pdf" />
                                    <div class="pdf-viewer" v-else>
                                        <div @click="openPdf(form.de.pdf)" class="pdf-name">{{form.de.pdf.split('_')[1]  }}</div>
                                        <div class="pdf-delete icon-otw_icon_trash" @click="deletePdf(form.de.pdf, 'de')"></div>
                                    </div>
                                    <div class="error-msg" v-if="errorPdfDE">{{ errorPdfDE }}</div>
                                  </div>
                                </template>
                                <template v-else>
                                  <!-- LINK -->
                                    <div class="form-group" :class="{ error: v$.form.de.link.$errors.length }">
                                      <div class="label">EXTERNAL LINK</div>
                                        <input class="form-control" placeholder="LINK" type="text" v-model="v$.form.de.link.$model">
                                        <!-- error message -->
                                        <template v-if="v$.form.de.link.$errors.length > 0">                
                                            <div class="error-msg" v-if="v$.form.de.link.$errors[0].$validator == 'required'">This field is required.</div>
                                            <div class="error-msg" v-if="v$.form.de.link.$errors[0].$validator == 'url'">Please select a valid URL format.</div>
                                            <div class="error-msg" v-if="v$.form.de.link.$errors[0].$validator == 'max'">{{v$.form.de.title.$errors[0].$params.max}} caracters maximum allowed.</div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                          </div>
                      </div>

                    </template>           
                  </div>

                  <!-- RIGHT ROW -->
                  <div class="col-img">
                      <div class="img-container">
                        <div class="label">FEATURED IMAGE<br /><span class="img-notices">.jpeg, .png - 2Mo max<br /> recommended size 1080px * 608px (aspect ratio 16:9) </span></div>
                        <div class="generate" v-if="generate">Generate thumnails ...</div>
                        <div class="img-content" v-else>
                          <div class="del-img icon-otw_icon_trash" @click="deleteFile()" v-if="imgFeatured != 'default' + $route.params.cat + '.jpg'"></div>
                          <img :src="`${$store.state.assetsUrl}/form-${imgFeatured}`" class="img-thumb"  />
                        </div>
                        <input type="file" class="img-input form-control" v-on:change="handleFileUpload($event)" name="featuredImg" accept="image/*" />
                        <div class="error-msg" v-if="errorImg">{{ errorImg }}</div>
                      </div>
                      <div class="form-group date-container" :class="{ error: v$.form.en.title.$errors.length }">
                            <div class="label">DATE</div>
                            <input class="form-control date-input" :max="new Date().toISOString().split('T')[0]" type="date" v-model="v$.form.date.$model">
                            <!-- error message -->
                            <template v-if="v$.form.date.$errors.length > 0">                
                                <div class="error-msg" v-if="v$.form.date.$errors[0].$validator == 'required'">This field is required.</div>
                                <div class="error-msg" v-if="v$.form.date.$errors[0].$validator == 'maxValue'">Date can't be in the future.</div>
                            </template>
                        </div>
                  </div>
                </div>
                         <!-- 
                    ***************************************************************************************  
                     SITE CHOICE
                    ***************************************************************************************  
                     -->
                     <div class="row row-choice">
                        <div class="col-100">
                          <div class="label">WEBSITES</div>
                          <div class="row row-type">
                                <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(1)},  {error : !form.sites.length }]" @click="switchSite(1)"></div>
                                  <div class="type-label">Safe Group</div>
                              </div>
                                <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(2)},  {error : !form.sites.length }]" @click="switchSite(2)"></div>
                                  <div class="type-label">Safe Orthopaedics</div>
                              </div>
                              <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(3)},  {error : !form.sites.length }]" @click="switchSite(3)"></div>
                                  <div class="type-label">Safe Medical</div>
                              </div>
                              <div :class="['type-item', {disabled : !langs.includes('en') }]">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(4)},  {error : !form.sites.length }]" @click="switchSite(4)"></div>
                                  <div class="type-label">LinkedIn</div>
                              </div>
                          </div>
                          <div class="error-msg error-center" v-if="!form.sites.length">Select at least one website</div>
                        </div>
                      </div>

                    <!-- 
                    ***************************************************************************************  
                    SUBMIT BUTTON
                    ***************************************************************************************  
                     -->
                    <div class="row-btn">
                        <input type="submit" :disabled="v$.form.$invalid || !form.sites.length || !langs.length" class="btn" value="Add" />
                    </div>     
        
            </form>
            <div class="notices errors" v-if="errors">An error has occured : 
              <div v-for="(item, idx) in errors" :key="idx">
                - {{ item }}
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, maxLength, url } from '@vuelidate/validators';
import Editor from '@tinymce/tinymce-vue';
import axios from 'axios';

export default {
    name  : "AddForm",
    components : {
      Editor
    },
    data() {
        return {
            generate : false,
            errorImg : null,
            errorPdfEN : null,
            errorPdfFR : null,
            errorPdfDE : null,
            title : null,
            type : 2,
            langs : ["fr"],
            imgFeatured : "",
            loading: false,
            errors : null,
            form : {
              sites : [],
              date : new Date().toISOString().slice(0,10),
              en : {
                title : null,
                text : null,
                link : null,
                pdf : null,
              },
              fr : {
                title : null,
                text : null,
                link : null,
                pdf : null,
              },
              de : {
                title : null,
                text : null,
                link : null,
                pdf : null,
              }
            }
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            form : {     
                date : {
                  required
                },
                en : {
                  title : {
                    requiredIf : requiredIf(this.langs.includes("en")), max : maxLength(255)
                  },
                  link : {
                    requiredIf : requiredIf(this.langs.includes("en") && this.type == 3), max : maxLength(255), url
                  },
                  text : {
                    requiredIf : requiredIf(this.langs.includes("en") && this.type == 1)
                  },
                  pdf : {
                    requiredIf : requiredIf(this.langs.includes("en") && this.type == 2)
                  }
                },
                fr : {
                  title : {
                    requiredIf : requiredIf(this.langs.includes("fr")), max : maxLength(255)
                  },
                  link : {
                    requiredIf : requiredIf(this.langs.includes("fr") && this.type == 3), max : maxLength(255)
                  }, 
                  text : {
                    requiredIf : requiredIf(this.langs.includes("fr") && this.type == 1)
                  },
                  pdf : {
                    requiredIf : requiredIf(this.langs.includes("fr") && this.type == 2)
                  }
                },
                de : {
                  title : {
                    requiredIf : requiredIf(this.langs.includes("de")), max : maxLength(255)
                  },
                  link : {
                    requiredIf : requiredIf(this.langs.includes("de") && this.type == 3), max : maxLength(255), url
                  },
                  text : {
                    requiredIf : requiredIf(this.langs.includes("de") && this.type ==  1)
                  },
                  pdf : {
                    requiredIf : requiredIf(this.langs.includes("de") && this.type == 2)
                  }
                },                
            }
        }        
    },
    mounted() {
        switch(this.$route.params.cat) {
            case "1" : 
                this.title = "News";
                break;
            case "2" : 
                this.title = "Events";
                break;
            case "3" : 
                this.title = "Press Release";
                break;
            case "4" : 
                this.title = "Press Release";
                break;
            case "5" : 
                this.title = "Career";
                break;
            case "6" : 
                this.title = "Documents";
                break;
            case "7" : 
                this.title = "Shareholders Meeting";
                break;
        }
        this.getDefaultImg();
    },
    methods : {
      switchLang(lang) {
        this.langs.includes(lang)  ? this.langs = this.langs.filter(e => e != lang) : this.langs.push(lang);
        if(lang == 'en' && !this.langs.includes('en') && this.form.sites.includes(4)) {
          const index = this.form.sites.indexOf(4);
          this.form.sites.splice(index, 1);
        }
      },
      switchSite(site) {
        if(!this.langs.includes('en') && site == 4) {
          const index = this.form.sites.indexOf(4);
          if(index > -1) {
            this.form.sites.splice(index, 1);
          }
        }else {
          if(site == 4 && !this.form.sites.includes(4) &&  !this.$cookies.isKey("_safeLKToken") && this.langs.includes('en')) {
            this.getLkToken();
          }
          this.form.sites.includes(site)  ? this.form.sites = this.form.sites.filter(e => e != site) : this.form.sites.push(site);
        }
       
      },
      handleFileUpload(event){
        const formData = new FormData();
        formData.append('featuredImg', event.target.files[0]);

        let config = {
            validateStatus: () => true,            
        };
        config.headers = { 'Content-Type': 'multipart/form-data', 'x-access-token' : localStorage._safeToken }
        let vm = this;
        this.generate = true;
        this.errorImg = null;
        axios
           .post(`${this.$store.state.apiUrl}/upload-img`, formData, config)
                .then(function (res) {
                    if(res.status != 200) {
                        vm.errorImg = res.data.message;
                        vm.generate = false;
                    }else{
                      //vm.imgFeatured= res.data.img
                      setTimeout(function() {vm.imgFeatured= res.data.img} , 2000 );
                      setTimeout(function() {vm.generate = false} , 2000 );
                    }
         
                })   
      },
      handlePdfUpload(event, lang){
        if(lang == 'en') {
          this.errorPdfEN= null;
        }else if(lang == 'fr') {
          this.errorPdfFR= null;
        }else {
          this.errorPdfDE= null;
        }
        const formData = new FormData();
        formData.append('pdf', event.target.files[0]);

        let config = {
            validateStatus: () => true,            
        };
        config.headers = { 'Content-Type': 'multipart/form-data', 'x-access-token' : localStorage._safeToken }
        let vm = this;
        axios
           .post(`${this.$store.state.apiUrl}/upload-pdf`, formData, config)
                .then(function (res) {
                    if(res.status != 200) {
                      if(lang == 'en') {
                        vm.errorPdfEN= res.data.message;
                      }else if(lang == 'fr') {
                        vm.errorPdfFR= res.data.message;
                      }else {
                        vm.errorPdfDE= res.data.message;
                      }
                    }else{
                      if(lang == 'en') {
                        vm.form.en.pdf= res.data.pdf;
                      }else if(lang == 'fr') {
                        vm.form.fr.pdf= res.data.pdf;
                      }else {
                        vm.form.de.pdf= res.data.pdf;
                      }
                      
                    }
         
                })   
      },
      getDefaultImg() {
        this.imgFeatured = "default" + this.$route.params.cat + ".jpg"
      },
      deleteFile() {
        
        let config = {
            validateStatus: () => true,  
        };
         config.headers = {  'x-access-token' : localStorage._safeToken }
        let vm = this;
        axios
           .get(`${this.$store.state.apiUrl}/delete-file/${this.imgFeatured}`,  config)
                .then(function (res) {
                  console.log(res.status)
                    if(res.status != 200) {
                      console.log('error deleted files:' + res.data.message)                      
                    }else{
                      vm.getDefaultImg();
                    }
         
                }) 
      },
      deletePdf(filename, lang) {
        let config = {
            validateStatus: () => true,  
        };
         config.headers = {  'x-access-token' : localStorage._safeToken }
        let vm = this;
        axios
           .get(`${this.$store.state.apiUrl}/delete-file/${filename}`,  config)
                .then(function (res) {
                  console.log(res.status)
                    if(res.status != 200) {
                      console.log('error deleted files:' + res.data.message)                      
                    }else{
                      if(lang == 'en') {
                        vm.form.en.pdf= null;
                      }else if(lang == 'fr') {
                        vm.form.fr.pdf= null;
                      }else {
                        vm.form.de.pdf= null;
                      }
                    }
         
                }) 
      },
      openPdf(filename) {
        window.open(`${this.$store.state.apiUrl}/public/pdfs/${filename}`, '_blank');
      },        
      async onSubmit() {

            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) return;

            this.loading = true;
            this.errors = null;

            let data = {
              "cat" : this.$route.params.cat,
              "type" : this.type,
              "langs" : this.langs,
              "imgFeatured" : this.imgFeatured,
              "websites" : this.form.sites,
              "published" : this.form.date,
              "en" : this.form.en,
              "fr" : this.form.fr,
              "de" : this.form.de,
              "lKToken" : this.$cookies.get('_safeLKToken')
            }

            let config = {
                validateStatus: () => true,
            };
            config.headers = {  'x-access-token' : localStorage._safeToken }
            let vm = this

            axios
                .post(`${this.$store.state.apiUrl}/post-add`, data, config)
                .then(function (res) {
                    if(res.status != 200) {
                      vm.errors = res.data.errors;                     
                    }else {
                        const notice = {
                                msg : "Post add with success!",
                                error : false
                            }
                        vm.$store.commit('setNotice', notice);
                        vm.success = true;
                        switch(vm.$route.params.cat) {
                          case "1" : {
                            vm.$router.push({name : "News"});
                            break;
                          }
                          case "2" : {
                            vm.$router.push({name : "Events"});
                            break;
                          }
                          case "3" : {
                            vm.$router.push({name : "PressRelease"});
                            break;
                          }
                          case "4" : {
                            vm.$router.push({name : "PressReview"});
                            break;
                          }
                          case "5" : {
                            vm.$router.push({name : "Career"});
                            break;
                          }
                          case "6" : {
                            vm.$router.push({name : "Documents"});
                            break;
                          }
                          case "7" : {
                            vm.$router.push({name : "ShareholdersMeeting"});
                            break;
                          }
                        }
                    }
         
                })     
        },

        getLkToken() {
            console.log('cookie : ' + this.$cookies.keys());
            window.open(`${this.$store.state.apiUrl}/linkedin/auth`, "_blank");
        }

    }
}
</script>

<style lang="scss" scoped>
  h1 {
    color:#fff;
    text-align: center;
    font-size:25px;
    text-transform: uppercase;
    font-weight: 300;
    margin:40px auto;
  }

  h2 {
    text-align: left;
    color:$mainColor;
    font-size:20px;
    text-transform: uppercase;
    font-weight: 300;
    margin: 20px 1%;
  }

  .type-item.disabled .checkbox-input {
    cursor: not-allowed;
  }

  .type-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  
</style>